import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuthHeader } from 'react-auth-kit';
import { axiosRequestPut } from '../../network/axiosRequest'; // Ensure this import path is correct
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // Import SweetAlert2 CSS
import Header from '../../components/header';
import { Eye,EyeOff} from 'react-feather';


const EditProfile: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const authHeader = useAuthHeader();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); // For new password visibility toggle
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // For confirm password visibility toggle
  const navigate = useNavigate();

  // useEffect(() => {
  //   feather.replace(); // Initialize Feather icons
  // }, [showNewPassword]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match.',
        customClass: {
          confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        },
      });
      return;
    }

    if (newPassword.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Password must be at least 6 characters long.',
        customClass: {
          confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        },
      });
      return;
    }

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to update your password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
      },
    });

    if (result.isConfirmed) {
      try {
        const headers = { Authorization: authHeader() };
        const response = await axiosRequestPut(`/users/${userId}/update`, { password: newPassword }, headers);
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.message || 'Password updated successfully.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
        });
        setNewPassword('');
        setConfirmPassword('');
        navigate('/profile-settings');
      } catch (error) {
        console.error('Failed to update password:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          text: 'Failed to update password.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
        });
      }
    }
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Profile', url: '/profile-settings' },
    { label: 'Change Password' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Change Password" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Change Password</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb: any, index: any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 bg-light rounded mb-4">
              <div className="bg-white border rounded p-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label className="font17 mb-0 font500">New Password</label>
                    <div className="input-group">
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                        className="form-control"
                        style={{ color: 'grey', borderRight: 'none'  }}
                      />
                      <span
                        className="input-group-text"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{ cursor: 'pointer' }}
                      >
                        {showNewPassword ? <EyeOff/> : <Eye/>} {/* Toggle between eye and eye-slash */}
                      </span>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="font17 mb-0 font500">Confirm Password</label>
                    <div className="input-group" >
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="form-control"
                        style={{ color: 'grey', borderRight: 'none' }}
                      />
                      <span
                        className="input-group-text"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        style={{ cursor: 'pointer' }}
                      >
                        {showConfirmPassword ? <EyeOff/> : <Eye/>} {/* Toggle between eye and eye-slash */}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 d-flex" style={{ justifyContent: 'space-between' }}>
                    <button type="submit" className="btn btn-primary primary-filled-button py-2 px-4">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </section>
  );
};

export default EditProfile;
