import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/template/images/logo-icon.svg';
import avatar from '../assets/template/images/user.png';
import { RequireAuth, useAuthUser, useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { axiosRequestGet } from '../network/axiosRequest';
import feather from 'feather-icons';

// Define the type for breadcrumb items
interface Breadcrumb {
    label: string;
    url?: string;
}

// Define the type for user details
interface UserDetails {
    first_name: string;
    last_name: string;
    profile_image: string;
    // Add any other fields that you are fetching from the API
}

// Define the props interface
interface HeaderProps {
    pageTitle: string;
    breadcrumbs: Breadcrumb[];
    enable: boolean;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, breadcrumbs, enable }) => {
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
    const signOut = useSignOut();
    const auth = useAuthUser();
    const token = useMemo(() => auth()?.token, [auth]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const headers = {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                };
                const userDetails = await axiosRequestGet('/users/details', headers);
                setUserDetails(userDetails as UserDetails);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        feather.replace(); // Initialize Feather icons
      }, []);

    const handleLogout = () => {
        console.log("Logout button clicked");
        signOut();
        setTimeout(() => {
            window.location.href = '/';
        }, 500);
    };
    

    return (
        <header className="top-header">
            <div className="d-flex align-items-center">
            <button className="btn close_btn me-md-3 me-2 px-0 ">
                <i data-feather="menu"></i>
            </button>
                <div className="hed_logo d-md-none d-block">
                    <img src={logo} className="img-fluid ms-2 w-75" alt="Logo" />
                </div>
                <div className="page-title-box d-md-block d-none">
                    <h2 className="page-title">{pageTitle}</h2>
                    {enable ?
                        <div className="breadcrumb">
                            {breadcrumbs.map((breadcrumb, index) => (
                                <span key={index}>
                                    {breadcrumb.url ? (
                                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                                    ) : (
                                        <span>{breadcrumb.label}</span>
                                    )}
                                    {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                                </span>
                            ))}
                        </div>
                        : <p className="mb-0">Let’s start writing copy and getting clients!</p>}
                </div>
            </div>

            <div className="top-header-right">
                <div className="header-actions d-lg-flex d-none">
                    <Link to="/contact-us"> <i data-feather="message-circle"></i> Feedback</Link>
                    {/* <Link to="/subscription-plan"> <i data-feather="plus"></i> Subscription</Link>
                    <Link to="/topup-credit"><i data-feather="dollar-sign"></i> Top-up Credits</Link> */}
                </div>
                <div className="dropdown header-user">
                    <div className="dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
                        <div className="header-user-img">
                            {userDetails?.profile_image ? (
                                <img
                                    src={userDetails.profile_image}
                                    style={{ width: '32px', height: '32px', objectFit: 'cover' }}
                                    onError={(e) => {
                                        (e.currentTarget as HTMLImageElement).src = 'https://via.placeholder.com/100'; // Fallback if image fails to load
                                    }}
                                />
                            ) : null}
                        </div>
                        <h5>{userDetails ? userDetails.first_name : ''}</h5>
                        <span data-feather="chevron-down"></span>
                    </div>
                    <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="/profile-settings">Profile</Link></li>
                        <li><Link className="dropdown-item" to="#" onClick={handleLogout}>Logout</Link></li>
                    </ul>
                </div>

            </div>
        </header>
    );
};

export default Header;
