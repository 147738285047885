import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest';
import { useAuthUser } from 'react-auth-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../../assets/template/css/subscription.css';
import '../../assets/template/css/fonts.css';
import '../../assets/template/css/bootstrap.min.css';
import Header from '../../components/header';
import Loader from '../../components/loader';
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';

interface Feature {
  er: string;
  text: string;
}

interface Subscription {
  id: number;
  stripe_plan_id: string;
  stripe_product_id: string;
  name: string;
  price: number;
  interval: string;
  token_point: number | null;
  features: Feature[];
}

const Subscription: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authData = auth();
  const userId = authData?.user_id;
  const token = auth()?.token;
  const [loading, setLoading] = useState(true);
  const location = useLocation(); 
  const [error, setError] = useState<string | null>(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState<Subscription[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<any>(null); // Changed to `any` for flexibility

  useEffect(() => {
    fetchSubscriptionPlans();
  }, [token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');

    if (status === 'success') {
      Swal.fire({
        title: 'Payment Successful!',
        text: 'Your payment has been successfully processed.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        },
        confirmButtonText: 'OK'
      });
    } else if (status === 'cancel') {
      Swal.fire({
        title: 'Payment Canceled',
        text: 'Your payment was canceled.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        },
        confirmButtonText: 'OK'
      });
    }
  }, [location.search]);

  const fetchSubscriptionPlans = async () => {
    try {
      const authData = auth();
  
      if (!authData || !authData.token) {
        throw new Error('User is not authenticated.');
      }
  
      const headers = {
        Authorization: `Bearer ${authData.token}`,
        "Content-Type": "application/json",
      };
  
      // Fetch subscription plans
      const plansResponse = await axiosRequestGet('/user/list_plans', headers);
      setSubscriptionPlans(plansResponse.plans || []);
  
      // Fetch user's active subscription
      const subscriptionResponse = await axiosRequestGet(`/user/active_subscription/${userId}`, headers);
      setActiveSubscription(subscriptionResponse.active_subscription || null);
  
      setLoading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          Swal.fire({
            title: 'Subscription Error',
            text: 'Your subscription has expired or is no longer available.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
            },
            confirmButtonText: 'OK'
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to load subscription plans. Please try again later.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
            },
            confirmButtonText: 'OK'
          });
        }
      } else {
        console.error('Unexpected error:', error);
        Swal.fire({
          title: 'Unexpected Error',
          text: 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
          confirmButtonText: 'OK'
        });
      }
      setLoading(false);
    }
  };

  const handleSubscribe = async (planId: number, tokenPoint: number | null) => {
    try {
      const authData = auth();
      const headers = {
        Authorization: `Bearer ${authData?.token}`,
        "Content-Type": "application/json",
      };

      const responseData = await axiosRequest('/create-checkout-session', {
        plan_id: planId,
        user_id: userId,
        token_point: tokenPoint,
      }, headers);

      if (responseData && responseData.url) {
        window.location.href = responseData.url;
      } else {
        throw new Error('Unable to redirect to payment.');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          Swal.fire({
            title: 'Subscription Already Active',
            text: 'This Subscription is already active.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
            },
            confirmButtonText: 'OK'
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Failed to load subscription plans. Please try again later.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
            },
            confirmButtonText: 'OK'
          });
        }
      } else {
        console.error('Unexpected error:', error);
        Swal.fire({
          title: 'Unexpected Error',
          text: 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary primary-filled-button py-2 px-4',
          },
          confirmButtonText: 'OK'
        });
      }
      setLoading(false);
    }
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Subscription Plans' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Subscription Plans" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid p-relative">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Subscription Plans</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb:any, index:any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {loading ? ( 
            <Loader />
          ) : subscriptionPlans.length > 0 ? (
            <div className="pricing-plans">
              {subscriptionPlans.map((plan) => (
                <div 
                  key={plan.id} 
                  className={`pricing-card ${activeSubscription && activeSubscription.plan_id === plan.id ? 'active' : 'basic'}`}
                >
                  <div className="heading">
                    <h4>{plan.name}</h4>
                    <p>for FunnelCopyKit</p>
                  </div>
                  
                  <p className="price">${plan.price} <sub>/{plan.interval}</sub></p>
                  <p className="mb-0 font-light-muted">
                    Credit Points: {plan.token_point ?? 'N/A'}
                  </p>
                 
                  <ul className='features'>
                    {plan.features.map((feature, index) => (
                      <li key={index}><i data-feather="check" style={{marginRight: '15px'}}></i>{feature.text}</li>
                    ))}
                  </ul>
                
                  <Button 
                    className="btn btn-primary primary-filled-button py-2 mx-auto" 
                    style={{padding: '10px 95px'}} 
                    onClick={() => handleSubscribe(plan.id, plan.token_point)}
                    disabled={!!(activeSubscription && activeSubscription.plan_id === plan.id)}
                  >
                    {activeSubscription && activeSubscription.plan_id === plan.id ? 'Subscribed' : 'Subscribe'}
                  </Button>
               
                </div>
              ))}
            </div>
          ) : (
            <div className="col-12 text-center">
              <p>No subscription plans available.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Subscription;
