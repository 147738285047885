import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequest } from '../../network/axiosRequest'; 
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../../components/header';
import Loader from '../../components/loader';

const ProfileSettings: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const token = auth()?.token;
  const userId = auth()?.user_id;
  const [userDetails, setUserDetails] = useState<{ first_name: string; last_name: string; email: string; points: number; password: string, id: number; profile_image: string; active2fa: boolean } | null>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeSubscription, setActiveSubscription] = useState<any>(null);

  useEffect(() => {
    if (isAuthenticated() && token) {
      const fetchUserDetails = async () => {
        try {
          const userData = await axiosRequestGet('/users/details', { Authorization: `Bearer ${token}` });
          setUserDetails(userData);

          // Fetch the active subscription details
          const subscriptionData = await axiosRequestGet(`/user/active_subscription/${userData.id}`, { Authorization: `Bearer ${token}` });
          setActiveSubscription(subscriptionData.active_subscription || null);
        } catch (error) {
          console.error('Failed to fetch user details or subscription:', error);
          setError('Failed to load user profile or subscription.');
        } finally {
          setLoading(false);
        }
      };

      fetchUserDetails();
    } else {
      setError('You are not authenticated.');
      setLoading(false);
    }
  }, [token]);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
        setProfileImage(e.target.files[0]);
    }
  };

  const handleUpdateProfile = async () => {
    if (userDetails) {
      const formData = new FormData();
      formData.append('first_name', userDetails.first_name);
      formData.append('last_name', userDetails.last_name);
      if (profileImage) {
          formData.append('profile_image', profileImage);
      }

      try {
          await axiosRequest(`/users/details/${userDetails.id}/update`, formData, {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
          });
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Profile updated successfully.',
            customClass: {
              confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
              cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
            }
          }).then(() => {
            window.location.reload();
          });
      } catch (error) {
          console.error('Failed to update profile:', error);
          setError('Failed to update profile.');
      }
    }
  };

  const handleCancelSubscription = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'Do you really want to cancel your subscription? This action cannot be undone.',
      showCancelButton: true,
      confirmButtonText: 'Yes, Cancel it!',
      cancelButtonText: 'No, Keep it',
      customClass: {
        confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
        cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with cancellation
        try {
          await axiosRequest(`/user/cancel_subscription/${userDetails?.id}`, {}, {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          });
          Swal.fire({
            icon: 'success',
            title: 'Subscription Canceled',
            text: 'Your subscription has been successfully canceled.',
            customClass: {
              confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
              cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
            }
          });
          setActiveSubscription(null);  // Clear the active subscription details after cancellation
        } catch (error) {
          console.error('Failed to cancel subscription:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to cancel the subscription.',
            customClass: {
              confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
              cancelButton: 'btn btn-danger danger-filled-button py-2 px-4'
            }
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Your subscription is still active.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          }
        });
      }
    });
  };
  

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Profile' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Profile" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Profile</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb:any, index:any) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {loading ?  <><Loader/></> :
          <>
          <div className="row">
          <div className="col-md-6">
            <div className="p-3 bg-light rounded mb-4">
              <div className="bg-white border rounded p-3">
                {userDetails ? (
                  <form>
                    <div className="form-group mb-3">
                      <img src={userDetails.profile_image} alt="" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}/>
                    </div>

                    {/* First Name */}
                    <div className="form-group mb-3">
                      <label className="font17 mb-0 font500" >First Name:</label>
                      <input 
                        type="text" 
                        value={userDetails.first_name} 
                        onChange={(e) => setUserDetails({ ...userDetails, first_name: e.target.value })}
                        className="form-control"
                        style={{ color: 'grey' }} 
                      />
                    </div>
                    
                    {/* Last Name */}
                    <div className="form-group mb-3">
                      <label className="font17 mb-0 font500">Last Name:</label>
                      <input 
                        type="text" 
                        value={userDetails.last_name} 
                        onChange={(e) => setUserDetails({ ...userDetails, last_name: e.target.value })}
                        className="form-control"
                        style={{ color: 'grey' }} 
                      />
                    </div>

                    {/* Profile Image */}
                    <div className="form-group mb-3">
                      <label className="font17 mb-0 font500" >Profile Image:</label>
                      <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleProfileImageChange}
                        className="form-control"
                        style={{ color: 'grey', height: '40px' }} 
                      />
                    </div>


                    {/* Update Button */}
                    <div className="mb-3 d-flex" style={{ justifyContent: 'space-between' }}>
                      <Link to={`/profile-edit/${userDetails.id}`}>
                        <button className="btn btn-primary primary-filled-button py-2 px-4">Change Password</button>
                      </Link>
                      <button type="button" onClick={handleUpdateProfile} className="btn btn-primary primary-filled-button py-2 px-4">
                        Update Profile
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>No user details available.</div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="p-3 bg-light rounded mb-4">
              <div className="bg-white border rounded p-3">
                {userDetails ? (
                  <form>
                    {activeSubscription ? (
                      <div className="form-group mb-3">
                        <label className="font32 mb-0 font500" style={{fontSize: 'x-large', color: '#f05a25'}}>Active Subscription:</label><br /><br />
                        <div className="p-2">
                          <strong style={{marginRight: '10px'}}>Plan:   </strong> {activeSubscription.name}<br /><br />
                          <strong style={{marginRight: '10px'}}>Price:   </strong> ${activeSubscription.price}/{activeSubscription.interval}<br /><br />
                          <strong style={{marginRight: '10px'}}>End Date:   </strong> {activeSubscription.end_date}<br /><br />
                          <div className="mb-3 text-end">

                          <button
                            type="button"
                            className="btn btn-danger danger-filled-button py-2 px-4"
                            onClick={handleCancelSubscription}
                          >
                            Cancel Subscription
                          </button>

                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>No active subscription.</div>
                    )}
                  </form>
                ) : (
                  <div>No user details available.</div>
                )}
              </div>
            </div>
          </div> */}
          </div>
          </>
          }
        </div>
      </div>
      <br /><br /><br />
    </section>
  );
};

export default ProfileSettings;
