import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthUser } from 'react-auth-kit';
import { axiosRequestGet, axiosRequest } from '../network/axiosRequest';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import '../assets/template/css/style.css';
import Header from '../components/header';

interface FormField {
  class: string;
  inputType: string;
  lenMsg: string;
  name: string;
  optional: boolean;
  options: { key: string; value: string }[];
  placeholder: string;
  requiredMsg: string;
  subtitle: string;
  tag: string;
  title: string;
  value: string;
}

interface FieldData {
  field: FormField[];
  id: number;
  name: string;
  status: number;
}

const FeedbackForm: React.FC = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const auth = useAuthUser();
  const token = auth()?.token;
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<FormField[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authData = auth();
        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const headers = {
          Authorization: `Bearer ${authData.token}`,
          'Content-Type': 'application/json',
        };

        // Fetch form fields
        const formFieldsResponse = await axiosRequestGet('/user/form_field', headers);
        const feedbackField = formFieldsResponse.find((field: FieldData) => field.id === 18);
        if (!feedbackField) {
          throw new Error('Feedback form field not found.');
        }
        setFormFields(feedbackField.field);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('Failed to fetch data:', error.message);
          setError('Failed to load data.');
        } else {
          console.error('An unknown error occurred:', error);
          setError('An unknown error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);


  const onSubmit = async (data: any) => {
    try {
      // Show confirmation dialog before submitting
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to submit this feedback?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          cancelButton: 'btn btn-danger danger-filled-button py-2 px-4',
        },
        confirmButtonText: 'Yes, submit feedback!',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        const authData = auth();
        if (!authData || !authData.token) {
          throw new Error('User is not authenticated.');
        }

        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        // Create FormData object
        const formData = new FormData();
        formData.append('query', selectedRadio || '');
        formData.append('description', data.description);
        if (data.screenshot[0]) {
          const file = data.screenshot[0];
          const fileType = file.type;
          const fileSize = file.size;
      
          // Check file type
          if (!allowedFileTypes.includes(fileType)) {
            Swal.fire({
                title: 'Invalid File Type',
                text: 'Only .jpeg, .jpg, and .png files are allowed.',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return;
        }
          // Check file size
          else if (fileSize > maxFileSize) {
            Swal.fire({
                title: 'File Too Large',
                text: 'File size should be less than 1MB.',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return;
        }
          // Append file if valid
          else {
              formData.append('screenshot', file);
          }
      }

        const headers = {
          "Authorization": `${auth()!.token}`,
          "Content-Type": "multipart/form-data", // Set content type for file uploads
      };

        const response = await axiosRequest('/user/feedbackform', formData, headers);

        console.log('Feedback submitted successfully!', response);
        Swal.fire({
          icon: 'success',
          title: 'Submitted!',
          text: 'Your feedback has been submitted.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
        });
        navigate('/dashboard');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Failed to submit feedback:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to submit feedback.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
        });
        setError('Failed to submit feedback.');
      } else {
        console.error('An unknown error occurred:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An unknown error occurred.',
          customClass: {
            confirmButton: 'btn btn-primary success-filled-button py-2 px-4',
          },
        });
        setError('An unknown error occurred.');
      }
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };

  const breadcrumbs = [
    { label: 'Dashboard', url: '/dashboard' },
    { label: 'Feedback Form' },
  ];

  return (
    <section className="main-right-wrapper">
      <Header pageTitle="Feedback" breadcrumbs={breadcrumbs} enable={true} />
      <div className="main-right-content">
        <div className="container-fluid">
          <div className="row wlcom_bx mb-3 d-md-none d-block">
            <div className="col-12">
              <div className="page-title-box">
                <h2 className="page-title">Ideal Client</h2>
                <div className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <span key={index}>
                      {breadcrumb.url ? (
                        <Link to={breadcrumb.url}>{breadcrumb.label}</Link>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && <span className="separator"> / </span>}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="p-3 bg-light rounded mb-4">
                <div className="bg-white border rounded p-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="font17 mb-0 font500">Feedback Type*</label> <br /><br />
                    {formFields && formFields.map((field, index) => (
                      <div key={index} className="form-group mb-3">
                        {field.tag === 'input' && field.inputType === 'radio' ? (
                          <div className="form-group mb-5">
                            <input
                            required
                              type={field.inputType}
                              value={field.class} // Use a unique value from each radio button
                              style={{ marginRight: '10px' }}
                              {...register(field.name, {
                                required: field.optional ? false : field.requiredMsg,
                                onChange: handleRadioChange,
                              })}
                            />
                     

                            <span style={{ fontSize: '14px' }}>{field.title}</span>
                          </div>
                        ) : field.tag === 'input' && field.inputType === 'file' ? (
                          <div className="form-group mb-5">
                            <label className="font17 mb-0 font500">{field.title}</label> <br />
                            <input
                              type={field.inputType}
                              className="form-control"
                              accept=".jpeg, .jpg, .png" 
                              {...register(field.name, {
                                required: field.optional ? false : field.requiredMsg,
                              })}
                            />
                            {errors[field.name] && (
                          <p style={{ color: 'red', fontSize: '12px' }}>
                            {errors[field.name]?.message?.toString() || `${field.title} is required`}
                          </p>
                        )}

                            {field.inputType === 'file' ? (
                              <p style={{ color: 'grey', fontSize: '13px' }}>
                                *File uploads are limited to .JPEG, .JPG and .PNG with a maximum file size of 1MB only.
                              </p>
                            ) : null}
                          </div>
                        ) : field.tag === 'input' ? (
                          <div className="form-group mb-5">
                            <label className="font17 mb-0 font500">{field.title}</label> <br />
                            <input
                              type={field.inputType}
                              className="form-control"
                              {...register(field.name, {
                                required: field.optional ? false : field.requiredMsg,
                              })}
                            />
                            {errors[field.name] && (
                          <p style={{ color: 'red', fontSize: '12px' }}>
                            {errors[field.name]?.message?.toString() || `${field.title} is required`}
                          </p>
                        )}

                          </div>
                        ) : field.tag === 'select' ? (
                          <select
                            className="form-control"
                            {...register(field.name, {
                              required: field.optional ? false : field.requiredMsg,
                            })}
                          >
                            {errors[field.name] && (
                          <p style={{ color: 'red', fontSize: '12px' }}>
                            {errors[field.name]?.message?.toString() || `${field.title} is required`}
                          </p>
                        )}

                            <option value="" disabled>Select an option</option>
                            {field.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.key}
                              </option>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    ))}
                    <br />
                    <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                      <button
                        type="submit"
                        className="btn btn-primary primary-filled-button py-2 px-4"
                      >
                        Submit Feedback
                      </button>
                    </div>
                    <br />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="p-3 bg-light rounded mb-4">
                <div className="bg-white border rounded p-3">
                  <form>
                    <p>
                      The form shown is a feedback submission form with the following fields and functions: <br /><br />
                      <b>Feedback Type Selection* :</b> <br />
                      You can choose an option from the provided radio buttons. <br />
                      You can choose from one of four options: <br /><br />
                  "Feedback"<br />
                  "Report a bug"<br />
                  "I have a question"<br />
                  "Feature Request"<br /><br />
                  This selection allows users to specify the nature of their submission, which helps categorize and prioritize the feedback. <br /><br />
                      <b>Additional Description* :</b> <br />
                      Provide detailed feedback or comments in the text area. <br /><br />
                      <b>Screenshot (optional):</b> <br />
                      You may upload a screenshot or image related to your feedback. Please note file size is limited to 1MB and formats are restricted to .JPEG, .JPG, and .PNG. <br /><br />
                      If you have any additional questions or require assistance, please feel free to contact us.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeedbackForm;
